import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaginationResponse } from 'src/app/core/interfaces/pagination.interface';
import { INegotiationDetailTruckingRequest, IRequestServiceInfo } from 'src/app/core/interfaces/request.interface';
import { IRequestServiceStatus } from 'src/app/core/interfaces/status.interface';
import { ITracking } from 'src/app/core/interfaces/tracking.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InlandFreightService {

  private readonly api = environment.api;
  private readonly endpoints = {
    negotiation: '/trucking/request/negotiation',
    assigned: '/trucking/request/assigned',
    transit: '/trucking/request/in-transit',
    serviceStatus: '/trucking/request/{uuid}/status',
    serviceInfo: '/trucking/request/{uuid}/info',
    completed: '/trucking/request/completed',
    startService: '/trucking/request/{requestId}/transit-pickup',
    tracking: '/request/{uuid}/tracking',
  }

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get Negotiation Services
   * @param page 
   * @param size 
   * @returns a list of services in negotiation
  **/
  getNegotiationServices(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}${this.endpoints.negotiation}?page=${page}&size=${size}&sort=updatedDate,desc`);
  }

  /**
   * Get Negotiation By Id
   * @param id 
   * @returns a negotiation 
  **/
  getNegotiationServiceById(id: string): Observable<INegotiationDetailTruckingRequest> {
    return this.http.get<INegotiationDetailTruckingRequest>(`${this.api}${this.endpoints.negotiation}/${id}`);
  }

  /**
   * Get Assigned Services
   * @returns a list of assigned services
   */
  getAssignedServices(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}${this.endpoints.assigned}?page=${page}&size=${size}&sort=updatedDate,desc`);
  }
  
  /**
   * Start Service
   * @param requestId 
   * @returns 
   */
  setInTransitService(requestId: number): Observable<any> {
    const endpoint = this.endpoints.startService.replace('{requestId}', requestId.toString());
    return this.http.post<any>(`${this.api}${endpoint}`, {});
  } 

  /**
   * Get Services in Transit
   * @param page 
   * @param size 
   * @returns a list of services in transit
  **/
  getTransitServices(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}${this.endpoints.transit}?page=${page}&size=${size}&sort=updatedDate,desc`);
  }

  /**
   * Get Service Information
   * @param id 
   * @returns 
   */
  getTrackingServiceInfo(id: string): Observable<IRequestServiceInfo> {
    const endpoint = this.endpoints.serviceInfo.replace('{uuid}', id);
    return this.http.get<IRequestServiceInfo>(`${this.api}${endpoint}`);
  }

  /**
   * Get Status Service Information
   * @param id 
   * @returns a list of statuses and events
  **/
  getTrackingStatusService(id: string): Observable<IRequestServiceStatus> {
    const endpoint = this.endpoints.serviceStatus.replace('{uuid}', id);
    return this.http.get<IRequestServiceStatus>(`${this.api}${endpoint}`);
  }

  /**
   * Get Completed Services
   * @returns a list of completed services
   */
  getCompletedServices(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}${this.endpoints.completed}?page=${page}&size=${size}&sort=updatedDate,desc`);
  }

  /**
   * Get Tracking
   * @param id 
   * @returns an object of route, events and statuses
   */
  getTracking(id: string): Observable <ITracking> {
    const endpoint = this.endpoints.tracking.replace('{uuid}', id);
    return this.http.get<ITracking>(`${this.api}${endpoint}`);
  }
}
