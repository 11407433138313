import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { IMapConfig, IMapConfiguration } from 'src/app/core/interfaces/map.interface';
import { mapStyle, mapStyleDark } from './map.styles';
import { IRealTimeTrackingEvent } from 'src/app/core/interfaces/tracking.interface';
import { auto } from '@popperjs/core';


@Component({
  selector: 'dollink-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  
  public map!: google.maps.Map;
  
  private readonly googleMapApiKey = environment.googleMapApiKey;
  private loader!: Loader;

  @Input() information!: IMapConfig;
  @Input() configuration!: IMapConfiguration;
  @Input() mapHeight = 0;

  constructor(private ref: ChangeDetectorRef) {
    this.loader = new Loader({
      apiKey: this.googleMapApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initMap();
  }

  initMap(): void {
    let boundOrigin: google.maps.LatLngBounds;
    this.loader
      .load()
      .then(() => {
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const directionsService = new google.maps.DirectionsService();
        boundOrigin = new google.maps.LatLngBounds();
        boundOrigin.extend(
          new google.maps.LatLng(
            this.configuration.pickup.latitude,
            this.configuration.pickup.longitude,
          )
        );
        this.map = new google.maps.Map(
          document.getElementById('map')! as HTMLElement,
          {
            center: boundOrigin.getCenter(),
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            scaleControl: false,
            scrollwheel: false,
            zoom: 8,
          }
        );
        this.map.setOptions({ styles: mapStyle });
        directionsRenderer.setMap(this.map);
        if(this.configuration.route) {
          this.drawCompletedRoute(this.configuration.route);
        } else {
          this.calculateRoute(directionsService);
        }
      })
      .catch((e) => {});
  }

  private calculateRoute(directionsService: google.maps.DirectionsService): void {
    let boundOrigin: google.maps.LatLngBounds;
    this.loader.load().then(() => {
      boundOrigin = new google.maps.LatLngBounds();
      boundOrigin.extend(
        new google.maps.LatLng(
          this.configuration.pickup.latitude,
          this.configuration.pickup.longitude,
        )
      );
      let request = {
        origin: new google.maps.LatLng(
          this.configuration.pickup.latitude,
          this.configuration.pickup.longitude,
        ),
        destination: new google.maps.LatLng(
          this.configuration.dropoff.latitude,
          this.configuration.dropoff.longitude,
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      };
      directionsService.route(request, (result, status) => {
        this.ref.detectChanges();
        if (String(status) === 'OK') {
          new google.maps.DirectionsRenderer({
            map: this.map,
            directions: result,
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: '#509760'
            }
          });

          this.createMarker(
            result!.routes[0].legs[0].start_location,
            'Punto de recolección',
            '/assets/images/icons/punto_recoleccion-point.png',
            this.map
          );

          this.createMarker(
            result!.routes[0].legs[0].end_location,
            'Punto de entrega',
            '/assets/images/icons/punto_entrega-point.png',
            this.map
          );
        } else {}
      });
    });
  }

  private drawCompletedRoute(route: string): void {
     // draw transited route
     const decodedRoute = google.maps.geometry.encoding.decodePath(route);
     const polylineRoute = new google.maps.Polyline({
       path: decodedRoute,
       geodesic: true,
       strokeColor: "#509760",
       strokeOpacity: 1.0,
       strokeWeight: 5,
     });
     polylineRoute.setMap(this.map);

    // set boundaries
    let bounds: google.maps.LatLngBounds;
    bounds = new google.maps.LatLngBounds();
    decodedRoute.forEach((item) => { bounds.extend(item) })
    this.map.fitBounds(bounds);

    // set pickup location marker
    new google.maps.Marker({
      position: new google.maps.LatLng(
        this.configuration.pickup.latitude,
        this.configuration.pickup.longitude,
      ),
      map: this.map,
      icon: {
        url: "/assets/images/icons/punto_recoleccion-point.png", // Car icon
        scaledSize: new google.maps.Size(40, 52), // Scale the car icon
      },
      title: "Punto de recolección",
    });

    // set dropoff marker 
    new google.maps.Marker({
      position: new google.maps.LatLng(
        this.configuration.dropoff.latitude,
        this.configuration.dropoff.longitude,
      ),
      map: this.map,
      icon: {
        url: "/assets/images/icons/punto_entrega-point.png",
        scaledSize: new google.maps.Size(40, 52),
      },
      title: "Punto de entrega",
    });

    if(this.configuration.events && this.configuration.events?.length > 0) {
      this.setEvents(this.configuration.events)
    }
  }

  private setEvents(events: IRealTimeTrackingEvent[]): void {
    events.forEach((event) => {
      // create new point
      const position =  new google.maps.LatLng(
        event.latitude,
        event.longitude,
      );

      let icon = "assets/svg/event-priority-low-icon.svg";
      if(event.priority === 'MEDIUM') {
        icon = "assets/svg/event-priority-medium-icon.svg";
      } if( event.priority === 'HIGH') {
        icon ="assets/svg/event-priority-high-icon.svg";
      }

      // set markers
      new google.maps.Marker({
        position: position,
        map: this.map,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(35, 35),
        },
        title: "Incidencia",
      });
    })
  }

  createMarker(
    latlng: google.maps.LatLng,
    title: string,
    icon: string,
    map: google.maps.Map
  ) {
    const marker = {
      url: icon,
      scaledSize: new google.maps.Size(40, 50)
    }
    new google.maps.Marker({
      position: latlng,
      map: map,
      title: title,
      icon: marker,
    });
  }
}
