import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { RequestTypes } from 'src/app/core/enums/request.enum';
import { parseStringToNumber } from 'src/app/core/helpers/global.helper';
import { CargoTypes } from 'src/app/core/interfaces/cargo-type.interface';
import { PackageTypes } from 'src/app/core/interfaces/package-type.interface';
import { IProjectCargos } from 'src/app/core/interfaces/projects.interface';
import { ICargos, IRequestCargoList } from 'src/app/core/interfaces/request.interface';
import { TrailerTypes } from 'src/app/core/interfaces/trailer-types.interface';
import { CargoTypeService } from 'src/app/core/services/cargo-type/cargo-type.service';
import { PackageTypeService } from 'src/app/core/services/package-type/package-type.service';
import { ProjectsService } from 'src/app/core/services/requestor/projects/projects.service';
import { TrailerTypeService } from 'src/app/core/services/trailer-type/trailer-type.service';
import { RequestsService } from 'src/app/core/services/trucking/requests/requests.service';
import { NUMBERS_ONLY_MASK } from 'src/app/shared/masks/masks';
import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-requestor-request-details',
  templateUrl: './request-details.component.html',
  styleUrl: './request-details.component.scss'
})
export class RequestDetailsComponent implements OnInit, OnDestroy {

  @Input() projectId = "";
  @Input() carga!: ICargos;
  @Input() type: RequestTypes | undefined = undefined;
  

  public cargoDetailsForm!: FormGroup;
  public cargoTypes: CargoTypes[] = [];
  public trailerTypes: TrailerTypes[] = [];
  public packageTypes: PackageTypes[] = [];
  public numberMask = NUMBERS_ONLY_MASK;
  public cargoList: IRequestCargoList[] = [];
  public submitted = false;
  public isSaving = false;
  public updateButtonText = "Guardar";
  public firstCargaId = 0;
  public netWeightError = false;
  public isFirstCarga = false;


  private getRequestDataSubscription!: Subscription; 

  constructor(
    private cargoTypeService: CargoTypeService,
    private packageTypeService: PackageTypeService,
    private trailerTypeService: TrailerTypeService,
    private projectService: ProjectsService,
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private requestTruckingService: RequestsService,
  ) {}

  ngOnInit(): void {
    
    this.createForm();
    this.getDataDropdowns();
    if(this.carga) {
      this.firstCargaId = this.carga.id;
      if(this.carga.number) {
        this.cargoDetailsForm.patchValue(this.carga);
        this.cargoDetailsForm.controls['length'].setValue(this.carga.length?.toString());
        this.cargoDetailsForm.controls['width'].setValue(this.carga.width?.toString());
        this.cargoDetailsForm.controls['height'].setValue(this.carga.height?.toString());
        this.cargoDetailsForm.controls['grossWeight'].setValue(this.carga.grossWeight?.toString());
        this.cargoDetailsForm.controls['netWeight'].setValue(this.carga.netWeight?.toString());
        this.cargoDetailsForm.controls['cargoTypeId'].setValue(this.carga.cargoType.id);
        this.cargoDetailsForm.controls['packageTypeId'].setValue(this.carga.packageType.id);
      } else {
        this.isFirstCarga = true;
      }
    }
  }

  ngOnDestroy(): void {
    if(this.getRequestDataSubscription) this.getRequestDataSubscription.unsubscribe()
  }

  public onClose(): void {
    this.modal.close();
  }


  public onChangeNetWeight(): void {
    if(this.cargoDetailsForm.controls['netWeight'].value) { 
      if(this.cargoDetailsForm.controls['grossWeight'].value) {        
        const grossWeight = parseStringToNumber(this.cargoDetailsForm.controls['grossWeight'].value);
        const netWeight = parseStringToNumber(this.cargoDetailsForm.controls['netWeight'].value);
        if(netWeight > grossWeight) {
          this.cargoDetailsForm.controls['netWeight'].setErrors({invalidNetWeight: true});
        } else {
          this.cargoDetailsForm.controls['netWeight'].setErrors(null);
        }
      }
    }
  }

  public onChangeGrossWeight(): void {
    if(this.cargoDetailsForm.controls['grossWeight'].value) {
      if(this.cargoDetailsForm.controls['netWeight'].value) {
        if(parseStringToNumber(this.cargoDetailsForm.controls['grossWeight'].value) < parseStringToNumber(this.cargoDetailsForm.controls['netWeight'].value)) {
          this.cargoDetailsForm.controls['netWeight'].setErrors({invalidNetWeight: true});
        } else {
          this.cargoDetailsForm.controls['netWeight'].setErrors(null);
        }
      }
    }
  }

  public onSave(): void {
    this.submitted = true;
    if(
      this.cargoDetailsForm.valid &&
      parseStringToNumber(this.cargoDetailsForm.value.length) &&
      parseStringToNumber(this.cargoDetailsForm.value.width) &&
      parseStringToNumber(this.cargoDetailsForm.value.height) &&
      !(this.cargoDetailsForm.value.netWeight && parseStringToNumber(this.cargoDetailsForm.value.netWeight) > parseStringToNumber(this.cargoDetailsForm.value.grossWeight))
    ) {
      this.isSaving = true;
      this.updateButtonText = 'Guardando';
      let data: IProjectCargos;
      if(this.isFirstCarga) {
        data = {
          ...this.cargoDetailsForm.value,
          length: parseStringToNumber(this.cargoDetailsForm.value.length),
          width: parseStringToNumber(this.cargoDetailsForm.value.width),
          height: parseStringToNumber(this.cargoDetailsForm.value.height),
          grossWeight: parseStringToNumber(this.cargoDetailsForm.value.grossWeight),
          id: this.firstCargaId
        }
      } else {
        if(this.carga) {
          this.cargoDetailsForm.controls['cargoTypeId'].setValue(this.carga.cargoType.id);
          this.cargoDetailsForm.controls['packageTypeId'].setValue(this.carga.packageType.id);
        }
        data = {
          ...this.cargoDetailsForm.value,
          length: parseStringToNumber(this.cargoDetailsForm.value.length),
          height: parseStringToNumber(this.cargoDetailsForm.value.height),
          grossWeight: parseStringToNumber(this.cargoDetailsForm.value.grossWeight),
          width: parseStringToNumber(this.cargoDetailsForm.value.width),
        }
        if(this.carga) data['id'] = this.firstCargaId; 
      }

      if(this.cargoDetailsForm.value.netWeight) {
        data['netWeight'] = parseStringToNumber(this.cargoDetailsForm.value.netWeight)
      }

      if(this.type === RequestTypes.PROJECT) {
        this.createProjectCargos(data);
      }

      if(this.type === RequestTypes.SELF_MANAGED) {
        this.createSelfManagedCargoService(data);
      }
    } else {
      this.cargoDetailsForm.markAllAsTouched();
    }
  }

  private createProjectCargos(data: any): void {
    this.projectService.setProjectCarga(this.projectId, data)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          this.modal.close(response);
        }
      })
  }

  private createSelfManagedCargoService(data: any): void {
    this.requestTruckingService.setProjectCarga(this.projectId, data)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          this.modal.close(response);
        }
      })
  }

  private getDataDropdowns(): void {
    this.getRequestDataSubscription = forkJoin({
      trailerTypes: this.trailerTypeService.getAll(),
      packageTypes: this.packageTypeService.getAll(),
      cargoTypes: this.cargoTypeService.getAll(),
    })
    .pipe(
      catchError((error) => {
        Swal.fire({
          title: error.title,
          text: error.description,
          allowEscapeKey: false,
          showConfirmButton: true,
          confirmButtonColor: "#509760",
        });
        return of(undefined);
      })
    )
    .subscribe((response) => {
      if(response) {
        this.trailerTypes = response.trailerTypes;
        this.packageTypes = response.packageTypes;
        this.cargoTypes = response.cargoTypes;
      }
    })
  }

  private createForm(): void {
    this.cargoDetailsForm = this.fb.group({
      number: ['', Validators.required],
      cargoTypeId: [null, Validators.required],
      packageTypeId: [null, Validators.required],
      length: [null, Validators.compose([Validators.required, Validators.min(1)])],
      width: [null, Validators.compose([Validators.required, Validators.min(1)])],
      height: [null, Validators.compose([Validators.required, Validators.min(1)])],
      grossWeight: [null, Validators.compose([Validators.required, Validators.min(1)])],
      netWeight: [null, Validators.compose([Validators.min(1)])],
      note: [''],
    })
  }
}
