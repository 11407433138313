import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { requestorNavigationItems, INavigation, truckingNavigationItems } from './menu';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestPickupComponent } from '../request/request-pickup/request-pickup.component';
import { RequestTypes } from 'src/app/core/enums/request.enum';
import { OnboardingService } from 'src/app/core/services/onboarding/onboarding.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'dollink-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public nav: INavigation | undefined = undefined;
  public user: any;
  public userType = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private modal: NgbModal,
    private onBoardingService: OnboardingService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getDecodedToken();
    const companyUserType = this.user.companyType;
    this.nav = companyUserType.toLowerCase() === 'transportistas' ? truckingNavigationItems : requestorNavigationItems;
    this.userType = companyUserType.toLowerCase() === 'transportistas' ? 'trucking' : 'requestor';
  }

  public onCreateRequest(e: Event, type: string): void {
    this.onBoardingService.showOnboarding()
      .pipe(
        catchError(() => {
          return of(undefined)
        })
      )
      .subscribe((response) => {
        if(response) {

          // show requestor onboarding
          if(type === RequestTypes.PROJECT) {
            if(
              !response.isDocumentsCompleted ||
              !response.isProfileCompleted 
            ) {
              this.onBoardingService.showRequestorOnboarding(response);
            } else {
              this.openRequestPickupModal(e, type);
            }
          }

          // show trucking onboarding
          if( type === RequestTypes.SELF_MANAGED) {
            if(
              response.drivers === 0 ||
              response.units === 0 ||
              !response.isDocumentsCompleted ||
              !response.isProfileCompleted 
            ) {
              this.onBoardingService.showTruckingOnboarding(response);
            } else {
              this.openRequestPickupModal(e, type);
            }
          }


        }
      })
  }

  private openRequestPickupModal(e: Event, type: string): void {
    e.preventDefault();
    const modalRef = this.modal.open(RequestPickupComponent, {
      centered: true,
      keyboard: true,
      backdrop: 'static',
      size: 'xl'
    })
    modalRef.componentInstance.pickup = undefined;
    modalRef.componentInstance.type = type;
    modalRef.closed.subscribe((response) => {
      if(response && response.id) {
        const url = type === RequestTypes.PROJECT ? `/requestor/projects/${response.uuid}` : `/trucking/requests/${response.uuid}`;
        this.router.navigate([url]);
      } else if(response && response.timestamp) {
        this.ngOnInit();
      } else if(response && response.isEditing) {
        modalRef.close();
      } else {
        modalRef.close();
      }
    });
  }

  public onSignOut(): void {
    this.authService.logout();
    this.router.navigate(['/auth/sign-in']);
  }
}
