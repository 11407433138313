import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { IMapConfiguration } from 'src/app/core/interfaces/map.interface';
import { mapStyle } from '../map/map.styles';
import { catchError, of, Subscription } from 'rxjs';
import { IRequestServiceInfoTrucking } from 'src/app/core/interfaces/request.interface';
import { TrackingSocketService } from 'src/app/core/services/socket/tracking-socket.service';
import { InlandFreightService } from 'src/app/core/services/trucking/inland-freight/inland-freight.service';
import { IRealTimeTracking, IRealTimeTrackingEvent } from 'src/app/core/interfaces/tracking.interface';
@Component({
  selector: 'dollink-tracking-map',
  templateUrl: './tracking-map.component.html',
  styleUrl: './tracking-map.component.scss'
})
export class TrackingMapComponent implements OnInit, OnDestroy {

  @Input() configuration!: IMapConfiguration;
  @Input() assignations: IRequestServiceInfoTrucking | undefined = undefined;

  @Output() newEvent = new EventEmitter<boolean>();
  @Output() newStatus = new EventEmitter<boolean>();

  public map!: google.maps.Map;
  public carMarker!: google.maps.Marker;
  
  private route: string =  "";
  private events: IRealTimeTrackingEvent[] = [];
  private realTimeTracking!: IRealTimeTracking;
  private loader!: Loader;
  private subscriptionTracking = new Subscription();
  private readonly googleMapApiKey = environment.googleMapApiKey;

  constructor(
    private ref: ChangeDetectorRef,
    private trackingSocketService: TrackingSocketService,
    private inlandService: InlandFreightService,
  ) {
    this.trackingSocketService.connect();
    this.loader = new Loader({
      apiKey: this.googleMapApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  ngOnInit(): void {
    this.getTracking();

    // subscribe to tracking socket
    this.subscriptionTracking = this.trackingSocketService.onGetLocations(this.configuration.requestId)
    .subscribe((data) => {
      if(data) {
        if(data.type === 'LOCATION') {
          this.realTimeTracking = data;
          this.realtimeTracking();
        }
        
        if(data.type === 'EVENT') {
          const event = {
            uuid: data.uuid,
            eventId: data.eventId,
            priority: data.priority,
            latitude: data.latitude,
            longitude: data.longitude,
            date: data.date,
            type: data.type
          }
          this.setEvents([event]);
          this.newEvent.emit(true);
        }

        if(data.type === 'STATUS') {
          this.newStatus.emit(true);
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    if(this.subscriptionTracking) this.subscriptionTracking.unsubscribe();
    this.trackingSocketService.disconnect();
  }
  
  private getTracking(): void {
    this.inlandService.getTracking(this.configuration.requestId)
    .pipe(
      catchError(() => {
        return of(undefined);
      })
    )
    .subscribe((response) => {
      if(response) {        
        this.route = response.route;
        if(response.events.length > 0) {
          response.events.forEach((event) => {
            this.events.push({
              uuid: this.configuration.requestId,
              eventId: event.eventId,
              priority: event.priority,
              latitude: event.point.latitude,
              longitude: event.point.longitude,
              date: event.date,
              type: 'EVENT'
            })
          })
        }

        this.initMap();
      }
    })
  }
  
  private initMap(): void {
    this.loader
      .load()
      .then(() => {
        // Map Configurations
        this.map = new google.maps.Map(document.getElementById('map')! as HTMLElement, {
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          scaleControl: false,
          //scrollwheel: false,
          //disableDefaultUI: true,
          zoom: 15,
          styles: mapStyle,
        });

        // set pickup location marker
        new google.maps.Marker({
          position: new google.maps.LatLng(
            this.configuration.pickup.latitude,
            this.configuration.pickup.longitude,
          ),
          map: this.map,
          icon: {
            url: "/assets/images/icons/punto_recoleccion-point.png", // Car icon
            scaledSize: new google.maps.Size(40, 52), // Scale the car icon
          },
          title: "Punto de recolección",
        });

        // set dropoff marker 
        new google.maps.Marker({
          position: new google.maps.LatLng(
            this.configuration.dropoff.latitude,
            this.configuration.dropoff.longitude,
          ),
          map: this.map,
          icon: {
            url: "/assets/images/icons/punto_entrega-point.png",
            scaledSize: new google.maps.Size(40, 52),
          },
          title: "Punto de entrega",
        });

        // draw transited route
        if(this.route !== '') {
          const route = google.maps.geometry.encoding.decodePath(this.route);
          const polylineRoute = new google.maps.Polyline({
            path: route,
            geodesic: true,
            strokeColor: "#509760",
            strokeOpacity: 1.0,
            strokeWeight: 5,
          });
          polylineRoute.setMap(this.map);
  
          // draw next route
          const directionsService = new google.maps.DirectionsService();
          let request = {
            origin: new google.maps.LatLng(route[route.length - 1]),
            destination: new google.maps.LatLng(
              this.configuration.dropoff.latitude,
              this.configuration.dropoff.longitude,
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          };
  
          directionsService.route(request, (result, status) => {
            this.ref.detectChanges();
            if (String(status) === 'OK') {
  
              if(result) {
                this.map.setCenter(result.routes[0].bounds.getCenter());
              }
  
              new google.maps.DirectionsRenderer({
                map: this.map,
                directions: result,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: '#A5AAA6',
                  strokeWeight: 5
                }
              });
            } else {}
          })
  
          // set car marker
          this.carMarker = new google.maps.Marker({
            position: route[route.length - 1],
            map: this.map,
            icon: {
              url: "/assets/images/icons/img-Locator-active@2x.png",
              scaledSize: new google.maps.Size(35, 35),
            },
            title: "Posición actual",
          });
        } else {
          // draw route
          const directionsService = new google.maps.DirectionsService();
          let request = {
            origin: new google.maps.LatLng(
              this.configuration.pickup.latitude,
              this.configuration.pickup.longitude,
            ),
            destination: new google.maps.LatLng(
              this.configuration.dropoff.latitude,
              this.configuration.dropoff.longitude,
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          };
  
          directionsService.route(request, (result, status) => {
            this.ref.detectChanges();
            if (String(status) === 'OK') {
  
              if(result) {
                this.map.setCenter(result.routes[0].bounds.getCenter());
              }
  
              new google.maps.DirectionsRenderer({
                map: this.map,
                directions: result,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: '#A5AAA6',
                  strokeWeight: 5
                }
              });

              this.map.setZoom(5);
            } else {}
          })
        }

        // set events markers 
        if(this.events.length > 0) {
          this.setEvents(this.events);
        }

      })
      .catch((e) => {});
  }

  private realtimeTracking(): void {
    // decode route and add new point
     const decodedRoute = google.maps.geometry.encoding.decodePath(this.route);
     const lastPoint = new google.maps.LatLng(this.realTimeTracking.latitude, this.realTimeTracking.longitude);
     decodedRoute.push(lastPoint);
     this.route = google.maps.geometry.encoding.encodePath(decodedRoute)

    // draw transited route and add marker in last point
    const route = google.maps.geometry.encoding.decodePath(this.route);
    const polylineRoute = new google.maps.Polyline({
      path: route,
      geodesic: true,
      strokeColor: "#509760",
      strokeOpacity: 1.0,
      strokeWeight: 5,
    });
    polylineRoute.setMap(this.map);

    this.carMarker.setPosition(route[route.length - 1])

    // draw next route based on last point to destination
    const directionsService = new google.maps.DirectionsService();
    let request = {
      origin: new google.maps.LatLng(
        this.realTimeTracking.latitude,
        this.realTimeTracking.longitude,
      ),
      destination: new google.maps.LatLng(
        this.configuration.dropoff.latitude,
        this.configuration.dropoff.longitude,
      ),
      travelMode: google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      this.ref.detectChanges();
      if (String(status) === 'OK') {
        new google.maps.DirectionsRenderer({
          map: this.map,
          directions: result,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#A5AAA6',
            strokeWeight: 5
          }
        });
      } else {}
    })
  }

  private setEvents(events: IRealTimeTrackingEvent[]): void {
    events.forEach((event) => {
      // create new point
      const position =  new google.maps.LatLng(
        event.latitude,
        event.longitude,
      );

      let icon = "assets/svg/event-priority-low-icon.svg";
      if(event.priority === 'MEDIUM') {
        icon = "assets/svg/event-priority-medium-icon.svg";
      } if( event.priority === 'HIGH') {
        icon ="assets/svg/event-priority-high-icon.svg";
      }

      // set markers
      new google.maps.Marker({
        position: position,
        map: this.map,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(35, 35),
        },
        title: "Posición actual",
      });
    })
  }
}
