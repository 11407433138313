import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHeaderTitle } from 'src/app/core/interfaces/header-service.interface';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private headerTitle = new BehaviorSubject<IHeaderTitle>({title: '', breadcrumbs: []});
  currentHeaderTitle = this.headerTitle.asObservable();

  constructor() { }

  public updateHeaderTitle(headerTitle: IHeaderTitle) {
    this.headerTitle.next(headerTitle);
  }

  public cleanHeaderTitle(): void {
    this.updateHeaderTitle({
      title: '', 
      breadcrumbs: []
    });
  }
}
