<div class="offcanvas-header" ngbAutofocus>
  <h4>
    Eventos
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="offcanvas-body">
  <div class="status">
    <div class="status-list">
      @for (status of serviceStatus?.statuses?.slice(3); track status.id) {
        <div class="status-block">
          <div class="status-title">
            <div class="icon me-3">
              <span class="material-symbols-outlined">
                <ng-container *ngIf="status.abbr === 'ASSI'">assignment_turned_in</ng-container>
                <ng-container *ngIf="status.abbr === 'WFSE'">schedule</ng-container>
                <ng-container *ngIf="status.abbr === 'INPI'">flag</ng-container>
                <ng-container *ngIf="status.abbr === 'PICK'">unarchive</ng-container>
                <ng-container *ngIf="status.abbr === 'INDR'">route</ng-container>
                <ng-container *ngIf="status.abbr === 'DROP'">archive</ng-container>
                <ng-container *ngIf="status.abbr === 'FINI'">sports_score</ng-container>
              </span>
            </div>
            <h5>{{status.name}}</h5>
          </div>
          @for (event of status.events; track event.id ) {
            <div class="event">
              <div class="icon">
                <i class="bi bi-arrow-90deg-left"></i>
              </div>
              <div class="info">
                <div class="event-owner">
                  <figure class="avatar">
                    <img *ngIf="event.owner.user.photo; else noUserPhoto" src="{{event.owner.user.photo}}" alt="{{event.owner.user.firstName}} User Owner Photo">
                    <ng-template #noUserPhoto>
                      <img src="/assets/images/avatars/img-operador-avatar@1x.jpg" alt="Default User Owner Photo">
                    </ng-template>
                  </figure>
                </div>
                <div class="details">
                  <p class="event-description mb-0">{{event.description}}</p>
                  <p class="owner-name">{{event.owner.user.firstName}} {{event.owner.user.lastName}}, {{event.createdDate | humanizeDate}}</p>
                  <ul class="event-files" *ngIf="event.files.length > 0">
                    @for (document of event.files; track $index; let last = $last) {
                      <li [class.lastItem]="last">
                        <i class="bi bi-image me-2"></i>
                        <a href="{{document.path}}" target="_blank">Ver foto</a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          } @empty {
            <p class="no-events">No hay eventos que mostrar.</p>
          }
        </div>
      }
    </div>
  </div>
</div>
