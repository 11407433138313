<div class="modal-header no-border custom-padding-project">
  <h2 class="mb-0">Detalles de la carga</h2>
</div>
<div class="modal-body custom-padding-project">
  <form [formGroup]="cargoDetailsForm">
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputNumber" class="form-label">No. de referencia</label>
        <div class="input-group has-validation">
          <input
            id="inputNumber"
            type="text"
            class="form-control"
            formControlName="number"
            [class.is-invalid]="submitted && cargoDetailsForm.controls['number'].errors"
          />
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['number'].hasError('required')">El número de referencia es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputCargoType" class="form-label">Selecciona el tipo de carga</label>
        <div class="input-group has-validation">
          <select
            id="inputCargoType"
            class="form-select"
            formControlName="cargoTypeId"
            [class.is-invalid]="submitted && cargoDetailsForm.controls['cargoTypeId'].errors"
          >
            <option [ngValue]="null" [disabled]="true">
              Tipo de carga
            </option>
            <option
              *ngFor="let cargo of cargoTypes"
              value="{{ cargo.id }}"
            >
              {{ cargo.name }}
            </option>
          </select>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['cargoTypeId'].hasError('required')">El tipo de carga es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputPackageType" class="form-label">Selecciona el tipo de embalaje</label>
        <div class="input-group has-validation">
          <select
            id="inputPackageType"
            class="form-select"
            formControlName="packageTypeId"
            [class.is-invalid]="submitted && cargoDetailsForm.controls['packageTypeId'].errors"
          >
            <option [ngValue]="null" [disabled]="true">
              Tipo de embalaje
            </option>
            <option
              *ngFor="let package of packageTypes"
              value="{{ package.id }}"
            >
              {{ package.name }}
            </option>
          </select>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['packageTypeId'].hasError('required')">El tipo de embalaje es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputNetLength" class="form-label">Largo</label>
        <div class="input-group has-validation">
          <input
            id="inputNetLength"
            type="text"
            class="form-control"
            formControlName="length"
            [imask]="numberMask"
            [class.is-invalid]="submitted && (cargoDetailsForm.controls['length'].errors || cargoDetailsForm.controls['length'].value === '0')"
          />
          <span class="input-group-text">cm</span>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['length'].hasError('required')">El largo es requerido</p>
            <p *ngIf="cargoDetailsForm.controls['length'].value === '0'">No puedes agregar 0 en el largo de la carga</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputNetWidth" class="form-label">Ancho</label>
        <div class="input-group has-validation">
          <input
            id="inputNetWidth"
            type="text"
            class="form-control"
            formControlName="width"
            [imask]="numberMask"
            [class.is-invalid]="submitted && (cargoDetailsForm.controls['width'].errors || cargoDetailsForm.controls['width'].value === '0')"
          />
          <span class="input-group-text">cm</span>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['width'].hasError('required')">El ancho es requerido</p>
            <p *ngIf="cargoDetailsForm.controls['width'].value === '0'">No puedes agregar 0 en el ancho de la carga</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputNetHeight" class="form-label">Alto</label>
        <div class="input-group has-validation">
          <input
            id="inputNetHeight"
            type="text"
            class="form-control"
            formControlName="height"
            [imask]="numberMask"
            [class.is-invalid]="submitted && (cargoDetailsForm.controls['height'].errors || cargoDetailsForm.controls['height'].value === '0')"
          />
          <span class="input-group-text">cm</span>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['height'].hasError('required')">El alto es requerido</p>
            <p *ngIf="cargoDetailsForm.controls['height'].value === '0'">No puedes agregar 0 en el alto de la carga</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputGrossWeight" class="form-label">Peso bruto</label>
        <div class="input-group has-validation">
          <input
            id="inputGrossWeight"
            type="text"
            class="form-control"
            formControlName="grossWeight"
            [imask]="numberMask"
            (blur)="onChangeGrossWeight()"
            [class.is-invalid]="submitted && cargoDetailsForm.controls['grossWeight'].errors"
          />
          <span class="input-group-text">kg</span>
          <div 
            *ngIf="submitted" 
            class="invalid-feedback"
          >
            <p *ngIf="cargoDetailsForm.controls['grossWeight'].hasError('required')">El peso bruto es requerido</p>
            <p *ngIf="cargoDetailsForm.controls['grossWeight'].value === '0'">No puedes agregar 0 en el peso bruto</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputNetWeight" class="form-label">
          Peso neto <small class="text-muted">Opcional</small>
        </label>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control"
            id="inputNetWeight"
            formControlName="netWeight"
            (blur)="onChangeNetWeight()"
            [class.is-invalid]="submitted && cargoDetailsForm.controls['netWeight'].errors"
            [imask]="numberMask"
          />
          <span class="input-group-text">kg</span>
          <div class="invalid-feedback" *ngIf="submitted || cargoDetailsForm.controls['netWeight'].errors">
            <p *ngIf="cargoDetailsForm.controls['netWeight'].value === '0'">No puedes agregar 0 en el peso neto</p>
            <p *ngIf="cargoDetailsForm.controls['netWeight'].hasError('invalidNetWeight')">
              El peso neto no puede ser mayor al peso bruto.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 mb-2">
        <label for="inputNotes" class="form-label">Notas / Descripción de la carga</label>
        <textarea class="form-control no-resize" id="inputNotes" formControlName="note"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer no-border custom-padding-project-actions">
  <button type="button" class="btn btn-cancel btn-outline" (click)="onClose()">Cancelar</button>
  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="onSave()">
    <span class="spinner-border spinner-border-sm me-2" aria-hidden="true" *ngIf="isSaving"></span>
    <span role="status">{{updateButtonText}}</span>
  </button>
</div>