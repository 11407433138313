<div class="notifications-center" ngbAutofocus>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Asignación de unidad</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseModal()"></button>
  </div>
  <div class="modal-body pt-5 pb-5">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="currentCargoUnit?.cargoUnit?.trailerType?.type ===  cargoUnitTypes.TRUCK">
          <p>
            Al seleccionar una unidad tipo TractoCamion, es importante agregar una caja.
          </p>
        </ng-container>
        <ng-container *ngIf="currentCargoUnit?.cargoUnit?.trailerType?.type ===  cargoUnitTypes.TRAILER">
          <p>
            Al seleccionar una unidad tipo Caja, es importante agregar un TractoCamion.
          </p>
        </ng-container>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <select class="form-select" [disabled]="true">
          <option [defaultSelected]="true">
            {{currentCargoUnit?.cargoUnit?.brand}} {{currentCargoUnit?.cargoUnit?.model}}
          </option>
        </select>
      </div>
      <div class="col-12 col-md-6">
        <select class="form-select" (change)="onSelectSecondUnit($event)">
          <option value="" [defaultSelected]="true">Selecciona una unidad</option>
          <option *ngFor="let truck of trucks" value="{{truck.cargoUnit.id}}">
            {{truck.cargoUnit.brand}} {{truck.cargoUnit.model}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-3 d-flex">
      <div class="selected-unit d-flex justify-content-end">
        <div class="d-flex flex-column text-end">
          <p class="m-0">{{currentCargoUnit?.cargoUnit?.brand}} {{currentCargoUnit?.cargoUnit?.model}}</p>
          <p class="m-0">Número de placa: {{currentCargoUnit?.cargoUnit?.mainUnitPlate}}</p>
        </div>
      </div>
      <div class="selected-units-images">
        <figure class="avatar avatar-xl">
          <img *ngIf="currentCargoUnit?.cargoUnit?.photo; else noCargoUnitPhotoOne;" [src]="currentCargoUnit?.cargoUnit?.photo" alt="Imágen de la unidad número {{currentCargoUnit?.cargoUnit?.number}}">
          <ng-template #noCargoUnitPhotoOne>
            <img src="/assets/images/avatars/img-unidad-avatar@1x.jpg" alt="Default Cargo Unit Photo">
          </ng-template>
        </figure>
        <figure class="avatar avatar-xl selection-unit">
          <img *ngIf="truckInfo?.cargoUnit?.photo; else noCargoUnitPhoto" [src]="truckInfo?.cargoUnit?.photo" alt="Imágen de la unidad número {{currentCargoUnit?.cargoUnit?.number}}">
          <ng-template #noCargoUnitPhoto>
            <img src="/assets/images/avatars/img-unidad-avatar@1x.jpg" alt="Default Cargo Unit Photo">
          </ng-template>
        </figure>
      </div>
      <div class="selected-unit d-flex justify-content-start">
        <div class="d-flex flex-column">
          <ng-container *ngIf="truckInfo?.cargoUnit; else noCargoUnitAssigned">
            <p class="m-0">{{truckInfo?.cargoUnit?.brand}} {{truckInfo?.cargoUnit?.model}}</p>
            <p class="m-0">Número de placa: {{truckInfo?.cargoUnit?.mainUnitPlate}}</p>
          </ng-container>
          <ng-template #noCargoUnitAssigned>
            <p class="m-0">Unidad sin asignar</p>
            <p class="m-0">Número de placa: N/A</p>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="submitted && !isSecondCargoUnitSelected">
      <div class="col-12">
        <div class="alert alert-danger" role="alert">
          Necesitas seleccionar la segunda unidad para poder asignar.
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel btn-outline" (click)="onCloseModal()">Cerrar</button>
    <button class="btn btn-primary" (click)="onAssignUnits($event)">Asignar unidades</button>
  </div>
</div>