import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { IRequestServiceStatus } from 'src/app/core/interfaces/status.interface';
import { InlandFreightService } from 'src/app/core/services/trucking/inland-freight/inland-freight.service';

@Component({
  selector: 'dollink-tracking-status-events',
  templateUrl: './tracking-status-events.component.html',
  styleUrl: './tracking-status-events.component.scss'
})
export class TrackingStatusEventsComponent implements OnInit, OnChanges{

  @Input() serviceId = "";

  public serviceStatus: IRequestServiceStatus | undefined = undefined;

  constructor(
    private inlandFreightService: InlandFreightService,
    private canvas: NgbActiveOffcanvas,
  ) {}

  ngOnInit(): void {
    if(this.serviceId) this.getTrackingInfo();
  }

  ngOnChanges(): void {}

  public onClose(): void {
    this.canvas.close();
  }

  private getTrackingInfo(): void {
    this.inlandFreightService.getTrackingStatusService(this.serviceId)
    .pipe(
      catchError(() => {
        return of(undefined);
      })
    )
    .subscribe((response) => {
      if(response) {
        this.serviceStatus = response;
      }
    })
  }

}
